import React, { memo } from "react";
import AxiomLoginForm from "./AxiomLoginForm";
import AxiomLoginD3Viz from "./AxiomLoginD3Viz";

function LogInWithBubble(props) {
  const {
    includeHeaderText,
    includeSubHeaderText,
    includeAppLogo,
    headerText,
    subHeaderText,
    loginLogoHeight,
    onChange,
    email,
    password,
    doLogin,
    isMonitorLoginEnabled,
    loginOptions,
    appLogo,
    onShowPasswordChange,
    showPassword,
    rememberMe,
    onRememberMeChange,
    hideForgotPassword,
    forgotPassword,
    forgotPasswordView,
    sendPasswordLink,
  } = props;

  return (
    <div className="axiom-login">
      <div className="axiom-login-form">
        <AxiomLoginForm
          includeHeaderText={includeHeaderText}
          includeSubHeaderText={includeSubHeaderText}
          includeAppLogo={includeAppLogo}
          headerText={headerText}
          subHeaderText={subHeaderText}
          appLogo={appLogo}
          loginLogoHeight={loginLogoHeight}
          onChange={onChange}
          email={email}
          password={password}
          doLogin={doLogin}
          isMonitorLoginEnabled={isMonitorLoginEnabled}
          loginOptions={loginOptions}
          onShowPasswordChange={onShowPasswordChange}
          showPassword={showPassword}
          rememberMe={rememberMe}
          onRememberMeChange={onRememberMeChange}
          hideForgotPassword={hideForgotPassword}
          forgotPassword={forgotPassword}
          forgotPasswordView={forgotPasswordView}
          sendPasswordLink={sendPasswordLink}
        />
      </div>
      <div className="axiom-login-information">
        <AxiomLoginD3Viz />
      </div>
    </div>
  );
}

export default memo(LogInWithBubble);
