import React from "react";
import PropTypes from "prop-types";
import IoAndroidArrowBack from "react-icons/lib/io/android-arrow-back";
import IoIosTimerOutline from "react-icons/lib/io/ios-timer-outline";
import IoAndroidMenu from "react-icons/lib/io/android-menu";
import IoAndroidMoreVertical from "react-icons/lib/io/android-more-vertical";
import IoAndroidCalendar from "react-icons/lib/io/android-calendar";
import IoFunnel from "react-icons/lib/io/funnel";
import IoAndroidNotifications from "react-icons/lib/io/android-notifications";
import logo from "./monitor.png";

import "./AxiomAppBar.scss";
import { AxiomAppContext } from "__AxiomComponents/AxiomLayout/AxiomAppContext";

export default class AxiomAppBar extends React.Component {
  static contextType = AxiomAppContext;
  constructor(props) {
    super(props);
    this.state = {
      isRightMenuOpen: false,
    };
  }

  onHistory = () => {
    AxiomAPIs.startHistoryMode();
  };

  toggleMenu = () => {
    this.setState({ isRightMenuOpen: !this.state.isRightMenuOpen });
  };

  render() {
    let styles = {
      axiomIcons: {
        color: this.context.axiomTheme.iconsColor,
      },
      axiomSize: {
        iconSize: 25,
      },
    };

    const { axiomTheme } = this.context;

    return (
      <div className="axiom-navbar" style={{ background: axiomTheme.appbarColor }}>
        <div className="axiom-navbar-left">
          <button
            onClick={this.props.onMenuClick}
            className="axiom-navbar-action axiom-navbar-menu"
          >
            {this.props.showPrevious ? (
              <IoAndroidArrowBack size={styles.axiomSize.iconSize} style={styles.axiomIcons} />
            ) : (
              <IoAndroidMenu size={styles.axiomSize.iconSize} style={styles.axiomIcons} />
            )}
          </button>
          <img
            className="axiom-navbar-logo"
            src={
              this.props.appLogo && this.props.appLogo != ""
                ? this.props.appLogo
                : this.props.projectLogo && this.props.projectLogo != ""
                ? this.props.projectLogo
                : logo
            }
            style={{ height: this.props.appLogoHeight }}
            alt="Logo"
          />
        </div>
        <div className="axiom-navbar-title">
          {this.props.title && <span>{this.props.title}</span>}
        </div>
        {(this.props.filterIcon ||
          this.props.calendarIcon ||
          this.props.historyIcon ||
          this.props.notificationIcon) && (
          <button onClick={this.toggleMenu} className="axiom-navbar-action axiom-navbar-toggle">
            <IoAndroidMoreVertical size={styles.axiomSize.iconSize} style={styles.axiomIcons} />
          </button>
        )}
        <div className={"axiom-navbar-right " + (this.state.isRightMenuOpen ? "is-open" : "")}>
          {this.props.filterIcon && (
            <button onClick={this.props.openFilterPanel} className="axiom-navbar-action">
              <IoFunnel size={styles.axiomSize.iconSize} style={styles.axiomIcons} />
              <span className="axiom-navbar-label">Filters</span>
            </button>
          )}
          {this.props.calendarIcon && (
            <button onClick={this.props.onCalendar} className="axiom-navbar-action">
              <IoAndroidCalendar size={styles.axiomSize.iconSize} style={styles.axiomIcons} />
              <span className="axiom-navbar-label">Select Date</span>
            </button>
          )}
          {this.props.historyIcon && (
            <button onClick={this.onHistory} className="axiom-navbar-action">
              <IoIosTimerOutline size={styles.axiomSize.iconSize} style={styles.axiomIcons} />
              <span className="axiom-navbar-label">History Mode</span>
            </button>
          )}
          {this.props.notificationIcon && (
            <button className="axiom-navbar-action">
              <IoAndroidNotifications size={styles.axiomSize.iconSize} style={styles.axiomIcons} />
              {this.props.notificationCount && this.props.notificationCount > 0 ? (
                <span className="axiom-navbar-badge">{this.props.notificationCount}</span>
              ) : null}
              <span className="axiom-navbar-label">Notifications</span>
            </button>
          )}
        </div>
      </div>
    );
  }
}
