import {
  useState,
  useEffect,
  useLayoutEffect,
  useCallback,
  useRef,
  useContext,
  useMemo,
} from "react";
import { AxiomAppContext } from "__AxiomComponents/AxiomLayout/AxiomAppContext";

// Use this hook to register your component as Axiom component
// This hook will provide you with a state object and a function to update the state
function useAxiomState(initialState = {}, appName, appID, componentName, componentID) {
  if (typeof initialState !== "object") {
    console.error(
      `Invalid initial axiom state provided for ${componentName}. initial state for Axiom component must be an object`
    );
    throw Error("INVALID_INITIAL_AXIOM_STATE");
  }

  if (appName == null || appID == null || componentName == null || componentID == null) {
    console.error(
      `appName: "${appName}", appID: "${appID}", componentName: "${componentName}", componentID: "${componentID}" are mandatory information`
    );
    throw Error("INVALID_AXIOM_STATE_REGISTRATION");
  }

  const [state, setState] = useState(initialState);
  const stateRef = useRef(initialState);

  useLayoutEffect(() => {
    // Register with Axiom on component mount
    AxiomAPIs.registerStateChange(appName, appID, {
      state: { componentName },
      props: { componentID },
      setState: (newState, cb) => {
        if (typeof newState !== "object") {
          console.error(
            `Invalid state provided for ${componentName}. state for Axiom component must be an object`
          );
        } else {
          setState((prevState) => ({ ...prevState, ...newState }));
          if (cb && typeof cb == "function") {
            setTimeout(() => {
              cb();
            }, 0);
          }
        }
      },
    });
    return () => {
      // Remove Axiom Registration on unmount
      AxiomAPIs.registeredApps[appName].appIDs[appID].registeredComponents[
        `${componentName}${componentID}`
      ].componentWillUnmount();
    };
  }, [appName, appID, componentName, componentID]);

  stateRef.current = state;

  const dispatch = useCallback(
    (data, type = "AxiomEventInfo", name = "setState") => {
      if (typeof data === "function") {
        data = data(stateRef.current);
      }
      // If event is local and no data is provided skip it
      if (data == null && type === "AxiomEventInfo") {
        return;
      } else {
        const event = new CustomEvent(type, {
          detail: {
            appName: appName,
            appID: appID,
            componentID: componentID,
            componentName: componentName,
            eventName: name,
            data: data,
          },
        });
        AxiomAPIs.dispatchEvent(event);
      }
    },
    [appName, appID, componentName, componentID]
  );

  return [state, dispatch];
}

// Use this hook to dispatch event to another Axiom Component
// This hook will provide you with a function to dispatch AxiomEvents for any Axiom component
function useAxiomEvent(appName, appID, componentName, componentID) {
  const dispatchEvent = useCallback(
    (data, type = "AxiomEventInfo", name = "setState") => {
      const event = new CustomEvent(type, {
        detail: {
          appName: appName,
          appID: appID,
          componentID: componentID,
          componentName: componentName,
          eventName: name,
          data: data,
        },
      });
      AxiomAPIs.dispatchEvent(event);
    },
    [appName, appID, componentName, componentID]
  );
  return dispatchEvent;
}

// Use this hook to fetch the app context like theme, appSettings
function useAxiomAppContext() {
  return useContext(AxiomAppContext);
}

function usePivotData(pivotConfig, KPIWiseFilters, dispatch) {
  if (pivotConfig.some((config) => typeof config !== "object") && !Array.isArray(pivotConfig)) {
    console.error(`Invalid pivotConfig. Pivot Config must be an array of objects`);
    throw Error("INVALID_PIVOT_CONFIG");
  }

  useEffect(() => {
    dispatch({ loading: true });
    AxiomAPIs.getPivotReportData(pivotConfig, KPIWiseFilters, (result) => {
      if (result) {
        dispatch(
          {
            pivotData: result,
            loading: false,
          },
          "AxiomEventInfo",
          "getPivotData"
        );
      } else {
        console.error("Error in getting Pivot Data");
        throw Error("Error in getting Pivot Data");
      }
    });
  }, [dispatch, pivotConfig, KPIWiseFilters]);
}

function useSetPivotData(data) {
  const updatedData = useMemo(() => __setPivotData(data), [data]);
  return updatedData;
}

function __setPivotData(data) {
  if (data && data.length) {
    let updatedData = [];
    let isAddingColumn = false;
    for (let i = 0; i < data.length; i++) {
      const reportKey = Object.keys(data[i])[0];
      let { pivotConfig, pivotData } = data[i][reportKey];
      const pivotConfigColumns = pivotConfig.columns;
      const pivotDataValueKey = pivotData[0][0].valueKey.map((e) => e.key);
      if (pivotConfigColumns.length != pivotDataValueKey.length) {
        isAddingColumn = true;
        let newValueKeys = [];
        pivotConfigColumns.forEach((ele, i) => {
          newValueKeys.push({
            key: ele.key,
            columnIndex: i,
          });
          if (pivotDataValueKey.indexOf(ele.key) === -1) {
            pivotData[0][0][ele.key] = 0;
            if (pivotData.length > 1) {
              pivotData[1].forEach((tableEle) => {
                tableEle[ele.key] = 0;
              });
            }
          }
        });
        pivotData[0][0].valueKey = newValueKeys;
      }
      let result = {};
      result[reportKey] = { pivotConfig: pivotConfig, pivotData: pivotData };
      updatedData.push(result);
    }
    return updatedData;
  }
  return data;
}

export { useAxiomState, useAxiomEvent, useAxiomAppContext, usePivotData, useSetPivotData };
