import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Hours from "./Hours.jsx";
import Minutes from "./Minutes.jsx";
import $ from "jquery";
import Picker from "react-mobile-picker";

function pad(n) {
  return n < 10 ? "0" + n : n;
}
const hours = [];
const hours24 = [];
const mins = [];
const ampm = ["AM", "PM"];

for (let i = 0; i <= 23; i++) {
  hours24.push(pad(i));
}

for (let i = 0; i <= 11; i++) {
  hours.push(pad(i));
}

for (let i = 0; i <= 59; i++) {
  mins.push(pad(i));
}

export default class TimePicker extends React.Component {
  constructor(props) {
    super(props);
    let hour = this.props.defaultHour();
    let min = this.props.defaultMinute();
    let ampmv = this.props.defaultAMPM();

    this.state = {
      hour: pad(hour) || hours[0],
      min: pad(min) || mins[0],
      ampm: ampmv || ampm[0],
      valueGroups: {
        hours: pad(hour) || hours[0],
        mins: pad(min) || mins[0],
        ampm: ampmv || ampm[0]
      },
      optionGroups: {
        hours,
        mins,
        ampm
      }
    };
  }

  handleChange = (name, value) => {
    this.setState(({ valueGroups }) => ({
      valueGroups: {
        ...valueGroups,
        [name]: value
      }
    }));
    this.props.timeChanged(this.state.valueGroups.hours + "." + this.state.valueGroups.mins + " " + (!this.props.hour24 ? this.state.valueGroups.ampm : ""));
  };

  handleHoursChange = event => {
    this.setState({
      hour: event.target.value
    });
    this.props.timeChanged(event.target.value + "." + this.state.min + " " + (!this.props.hour24 ? this.state.ampm : ""));
  };

  handleMinutesChange = event => {
    this.setState({
      min: event.target.value
    });
    this.props.timeChanged(this.state.hour + "." + event.target.value + " " + (!this.props.hour24 ? this.state.ampm : ""));
  };

  handleAMPMChange = event => {
    this.setState({
      ampm: event.target.value
    });
    this.props.timeChanged(this.state.hour + "." + this.state.min + " " + (!this.props.hour24 ? event.target.value : ""));
  };

  render() {
    const { optionGroups, valueGroups } = this.state;

    return (
      <div className="react-calendar__time-view">
        <div className="react-calendar__mobile">
          <Picker optionGroups={optionGroups} valueGroups={valueGroups} onChange={this.handleChange} />
        </div>
        <div className="react-calendar__desktop">
          <div className="styled-select slate">
            {!this.props.hour24 ? (
              <select value={this.state.hour} onChange={this.handleHoursChange}>
                {hours.map((item, index) => {
                  return (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            ) : (
              <select value={this.state.hour} onChange={this.handleHoursChange}>
                {hours24.map((item, index) => {
                  return (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            )}
          </div>
          <div className="styled-select slate">
            <select value={this.state.min} onChange={this.handleMinutesChange}>
              {mins.map((item, index) => {
                return (
                  <option key={index} value={item}>
                    {item}
                  </option>
                );
              })}
            </select>
          </div>{" "}
          {!this.props.hour24 ? (
            <div className="styled-select slate">
              <select value={this.state.ampm} onChange={this.handleAMPMChange}>
                {ampm.map((item, index) => {
                  return (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
