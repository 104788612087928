import React, { Component } from "react";
import Icons from "./Icons";

class Icon extends Component {
  render() {
    if (Icons[this.props.icon]) {
      return (
        <svg
          className={this.props.className}
          viewBox={Icons[this.props.icon].viewBox}
          dangerouslySetInnerHTML={{ __html: Icons[this.props.icon].data }}
          style={this.props.style || undefined}
        />
      );
    } else {
      return null;
    }
  }
}

export default Icon;
