import React, { Component } from "react";
import "./AxiomSpinner.css";

class AxiomSpinner extends Component {
  render() {
    return (
      <svg
        className={"axiom-spinner" + (this.props.className || "")}
        style={{ width: this.props.spinnerWidth || 60 }}
        viewBox="25 25 50 50">
        <circle
          stroke={this.props.spinnerColor || "var(--primary-color)"}
          className="axiom-spinner-circle"
          cx="50"
          cy="50"
          r="20"
        />
      </svg>
    );
  }
}

export default AxiomSpinner;
