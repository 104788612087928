import React, { PureComponent } from "react";

import TileGroup from "../TileGroup.jsx";
import Month from "./Month.jsx";

import { getYear } from "../shared/dates";
import { tileGroupProps } from "../shared/propTypes";

export default class Months extends PureComponent {
  start = 0;

  end = 11;

  get year() {
    const { activeStartDate } = this.props;
    return getYear(activeStartDate);
  }

  render() {
    const { activeStartDate, ...otherProps } = this.props;
    return <TileGroup {...otherProps} className="react-calendar__year-view__months" dateTransform={monthIndex => new Date(this.year, monthIndex, 1)} dateType="month" end={this.end} start={this.start} tile={Month} />;
  }
}

Months.propTypes = {
  ...tileGroupProps
};
