import React, { memo, Fragment } from "react";

import "./LandingPageCard.scss";
import Icon from "../Icons/Icon";
import { getIconColor } from "../homeUtils";

function LandingPageCard(props) {
  const { app, favouriteAppFunction, showAppIcon, favApps } = props;

  const iconName = showAppIcon && app.icon ? app.icon : "ICON_GROWTH";
  return (
    <Fragment>
      <a
        key={app.name}
        href={app.URL}
        target={app.urlOpenLocation}
        className="axiom-landing-app"
        tabIndex="0"
      >
        <Icon
          icon={iconName}
          className="axiom-landing-app-icon"
          style={{ fill: getIconColor(app.name) }}
        />
        <p className="axiom-landing-app-title">{AxiomAPIs.translate(app.name)}</p>
        {favouriteAppFunction && (
          <button onClick={(e) => favouriteAppFunction(e, app)} className="axiom-apps-app-action">
            <svg
              className={
                favApps ? `axiom-apps-app-star axiom-apps-app-favourite` : `axiom-apps-app-star`
              }
              viewBox="0 0 24 24"
            >
              <title>{favApps ? "Remove" : "Add"} as Favourite</title>
              <path d="M12 17.25l-6.188 3.75 1.641-7.031-5.438-4.734 7.172-0.609 2.813-6.609 2.813 6.609 7.172 0.609-5.438 4.734 1.641 7.031z"></path>
            </svg>
          </button>
        )}
      </a>
    </Fragment>
  );
}

export default memo(LandingPageCard);
