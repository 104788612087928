import React, { Component } from "react";
import ReactDOM from "react-dom";
import Container from "./Container";

let timer = null;
function checkAxiomAPIs() {
  if (typeof AxiomAPIs != "undefined") {
    clearInterval(timer);
    AxiomAPIs.registerApp("Home", renderReact, false);
  }
}
let Prompt;
function renderReact(element, props) {
  ReactDOM.render(
    <Container
      appName={props.appName}
      appID={props.appID}
      backgroundColor={props.backgroundColor}
      client={props.client}
      beforeinstallprompt={Prompt}
    />,
    element
  );
}

timer = setInterval(checkAxiomAPIs, 100);

window.addEventListener("beforeinstallprompt", (e) => {
  Prompt = e;
  console.log("Service worker");
});
