// This component is a replacement of AxiomLayout for HawkeyeV2
import React, { memo, useMemo, useCallback, useEffect } from "react";

import AxiomSnackBar from "__AxiomComponents/AxiomSnackBar/AxiomSnackBar.jsx";
import AxiomLoginNew from "__AxiomComponents/AxiomLogin/AxiomLoginNew.jsx";
import AxiomErrorBoundry from "__AxiomComponents/AxiomErrorBoundry/AxiomErrorBoundry";

import { useAxiomState } from "__AxiomComponents/AxiomHooks/AxiomHooks";

import "./AxiomLayoutComponent.scss";
import { AxiomAppContext } from "./AxiomAppContext";
import AxiomLanguageSelection from "./AxiomLanguageSelection.js";
import { languages } from "./languageMapping.js";

function AxiomApp(props) {
  const [state, dispatch] = useAxiomState(
    {
      logged: false,
      loading: true,
      snackOpen: false,
      isLogoutDisabled: false,
      snackMessage: "",
      snackType: "info",
      snackAutoHideDuration: 10000,
      snackErrorClass: "",
    },
    props.appName,
    props.appID,
    "AxiomLayout",
    props.componentID
  );

  useEffect(() => {
    if (
      state.preferredLanguage != null &&
      languages[state.preferredLanguage] != null &&
      typeof languages[state.preferredLanguage] == "object"
    ) {
      props.i18n.changeLanguage(languages[state.preferredLanguage].folderName, function () {
        dispatch({
          preferredLanguageMapping: languages[state.preferredLanguage],
        });
      });
    }
  }, [props.i18n, state.preferredLanguage, dispatch]);

  useEffect(() => {
    if (AxiomAPIs.theme) {
      AxiomAPIs.theme.forEach((item) => {
        document.body.style.setProperty(item.key, item.value);
      });
    }
  }, []);

  const logout = useCallback(() => {
    dispatch({ logged: false });
    AxiomAPIs.logout(function () {});
    setTimeout(() => {
      location.reload();
    }, 300);
  }, [dispatch]);

  const userInfo = useMemo(() => {
    return {
      name: state.userName,
      email: state.userEmailID,
      lastName: state.lastName,
      profilePhoto: state.profilePhoto,
    };
  }, [state.userName, state.userEmailID, state.profilePhoto, state.lastName]);

  // On login fetch initial data from server
  useEffect(() => {
    if (state.logged) {
      dispatch({}, "AxiomEvent", "mount");
    }
  }, [dispatch, state.logged]);

  // Update primary color variable according to theme
  useEffect(() => {
    if (state.appTheme && state.appTheme.length > 0) {
      state.appTheme.forEach((item) => {
        document.body.style.setProperty(item.key, item.value);
      });
    }
  }, [state.appTheme]);

  useEffect(() => {
    if (state.appSettings && state.appSettings.theme && state.appSettings.theme.length > 0) {
      state.appSettings.theme.forEach((item) => {
        document.body.style.setProperty(item.key, item.value);
      });
    }
  }, [state.appSettings]);

  const onSnackClose = () => {
    dispatch({
      snackOpen: false,
      snackMessage: "",
      snackType: "info",
      snackErrorClass: "",
    });
  };

  const context = useMemo(() => {
    return {
      isLogoutDisabled: state.isLogoutDisabled,
      isShowChangePassword: state.isShowChangePassword,
      appSettings: state.appSettings,
      userInfo: userInfo,
    };
  }, [state.isLogoutDisabled, state.appSettings, userInfo, state.isShowChangePassword]);

  const preferredLanguageSave = useCallback(
    (e, isSaveLanguage, isSelectedLanguage) => {
      if (e != null && typeof e.preventDefault == "function") e.preventDefault();
      if (
        isSelectedLanguage.value != null &&
        languages[isSelectedLanguage.value] != null &&
        typeof languages[isSelectedLanguage.value] == "object"
      ) {
        props.i18n.changeLanguage(languages[isSelectedLanguage.value].folderName);
      }
      dispatch(
        {
          language: isSelectedLanguage.value,
          isSaveLanguage: isSaveLanguage,
        },
        "AxiomEvent",
        "savePreferredLanguage"
      );
      dispatch({
        preferredLanguage: isSelectedLanguage.value,
      });
    },
    [dispatch, props.i18n]
  );

  if (state.logged && state.supportedLanguages) {
    if (
      !state.preferredLanguage &&
      state.supportedLanguages.length &&
      state.appSettings &&
      state.appSettings.enableLanguageSelection
    ) {
      return (
        <AxiomLanguageSelection
          appName={props.appName}
          appID={props.appID}
          componentID={props.componentID}
          preferredLanguageSave={preferredLanguageSave}
          preferredLanguage={state.preferredLanguage}
          supportedLanguages={state.supportedLanguages}
        />
      );
    } else {
      return (
        <div className="axiom-app">
          {!state.loading && state.renderChildComponent && (
            // Clone children to pass props
            <AxiomErrorBoundry>
              <AxiomAppContext.Provider value={context}>
                {React.cloneElement(React.Children.only(props.children), {
                  logout: logout,
                  isLogoutDisabled: state.isLogoutDisabled,
                  isShowChangePassword: state.isShowChangePassword,
                  appSettings: state.appSettings,
                  userInfo: userInfo,
                  preferredLanguageSave: preferredLanguageSave,
                  preferredLanguage: state.preferredLanguage,
                  supportedLanguages: state.supportedLanguages,
                  versionFileContent: state.versionFileContent,
                  isNotShowChildren: state.isNotShowChildren,
                })}
              </AxiomAppContext.Provider>
            </AxiomErrorBoundry>
          )}
          <AxiomSnackBar
            snackOpen={state.snackOpen}
            message={state.snackMessage}
            type={state.snackType}
            autoHideDuration={state.snackAutoHideDuration}
            onClose={onSnackClose}
            snackErrorClass={state.snackErrorClass}
          />
        </div>
      );
    }
  } else {
    return (
      <AxiomLoginNew
        appLogo={props.appLogo}
        loginLogoHeight={props.loginLogoHeight}
        appName={props.appName}
        appID={props.appID}
        componentID={props.componentID}
        includeHeaderText={
          props.hasOwnProperty("includeHeaderText") ? props.includeHeaderText : true
        }
        includeSubHeaderText={
          props.hasOwnProperty("includeSubHeaderText") ? props.includeSubHeaderText : true
        }
        includeAppLogo={props.hasOwnProperty("includeAppLogo") ? props.includeAppLogo : true}
        headerText={props.headerText}
        subHeaderText={props.subHeaderText}
        componentName={"AxiomLayout"}
      />
    );
  }
}

export default memo(AxiomApp);
