import React, { memo, Fragment, useMemo } from "react";
import "./AllApps.scss";
import LandingPageCard from "../LandingPageCard/LandingPageCard";

function AllApps(props) {
  const { loading, apps, favouriteApps, favouriteApp, unfavouriteApp, appSettings } = props;

  const allApps = useMemo(() => {
    return apps.filter((d) => !favouriteApps.some((f) => f.name === d.name));
  }, [favouriteApps, apps]);

  const backgroundStyle = {};

  if (appSettings && appSettings.dashboardBackground) {
    backgroundStyle.backgroundImage = `url(${appSettings.dashboardBackground})`;
    backgroundStyle.backgroundSize = "cover";
    backgroundStyle.backgroundRepeat = "no-repeat";
  }

  return (
    <div className="axiom-apps" style={backgroundStyle}>
      {favouriteApps.length > 0 && (
        <Fragment>
          <h2 className="axiom-apps-section-title">{AxiomAPIs.translate("Favourite Apps")}</h2>
          <div className="axiom-apps-section">
            {favouriteApps.map((app) => (
              <LandingPageCard app={app} favouriteAppFunction={unfavouriteApp} favApps={true} />
            ))}
          </div>
        </Fragment>
      )}
      {allApps.length > 0 && (
        <Fragment>
          <h2 className="axiom-apps-section-title">{AxiomAPIs.translate("All Apps")}</h2>
          <div className="axiom-apps-section">
            {allApps.map((app) => (
              <LandingPageCard app={app} favouriteAppFunction={favouriteApp} />
            ))}
          </div>
        </Fragment>
      )}
      {!loading && allApps.length === 0 && favouriteApps.length === 0 && (
        <div className="axiom-apps-blank">
          You do not have access to any application. Please contact the platform administrator to
          get started.
        </div>
      )}
    </div>
  );
}

export default memo(AllApps);
