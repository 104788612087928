import React, { Component } from "react";
import { HashRouter as Router } from "react-router-dom";
import AxiomLayout from "__AxiomComponents/AxiomLayout/AxiomLayout.jsx";
import Home from "../components/Home/Home.js";

function Container(props) {
  return (
    <Router basename="/">
      <AxiomLayout
        axiomTheme="axiomWhite"
        appName={props.appName}
        title={props.appName}
        appID={props.appID}
        componentID="0"
        authorized={props.authorized}
        layoutName="AxiomApp"
      >
        <Home
          appName={props.appName}
          appID={props.appID}
          componentID="0"
          beforeinstallprompt={props.beforeinstallprompt}
        />
      </AxiomLayout>
    </Router>
  );
}

export default Container;
