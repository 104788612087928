import React from "react";
import AxiomApp from "./AxiomApp";
import AxiomLayoutComponent from "./AxiomLayoutComponent";
import "./i18n";
import { useTranslation } from "react-i18next";

export default function AxiomLayout(props) {
  const { t, i18n } = useTranslation(["translation", "kpitranslation"]);
  if (AxiomAPIs != null) {
    AxiomAPIs.translate = t;
  }

  if (props.layoutName === "AxiomApp") {
    return <AxiomApp {...props} i18n={i18n} />;
  } else {
    return <AxiomLayoutComponent {...props} i18n={i18n} />;
  }
}

if ("serviceWorker" in navigator && location.protocol === "https:") {
  window.addEventListener("load", () => {
    if (location.pathname == "/apps/Home/") {
      navigator.serviceWorker
        .register("SW.js")
        .then((registration) => {
          console.info("SW registered: ", registration);
        })
        .catch((registrationError) => {
          console.error("SW registration failed: ", registrationError);
        });
    } else {
      navigator.serviceWorker
        .register("service-worker.js")
        .then((registration) => {
          console.info("SW registered: ", registration);
        })
        .catch((registrationError) => {
          console.error("SW registration failed: ", registrationError);
        });
    }
  });
}
