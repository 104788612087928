import React, { Fragment, memo, useEffect, useCallback, useMemo, useState } from "react";
import { Route, Link, useLocation, Redirect, useHistory } from "react-router-dom";

import { useAxiomState, useAxiomEvent } from "__AxiomComponents/AxiomHooks/AxiomHooks";
import AxiomLoader from "__AxiomComponents/AxiomUI/AxiomLoader/AxiomLoader.jsx";

import LandingPage from "../LandingPage/LandingPage";
import AllApps from "../AllApps/AllApps";
import ChangePassword from "../ChangePassword/ChangePassword";

import "./Home.scss";

import { checkMobileDevice } from "../homeUtils.js";

function Home(props) {
  const { appSettings, userInfo } = props;

  const [state, dispatch] = useAxiomState(
    {
      loading: true,
      apps: [],
      favouriteApps: [],
    },
    props.appName,
    props.appID,
    "Home",
    props.componentID
  );
  const [CloseModelAddtoHome, setclosemodelAddtoHome] = useState(true);
  useEffect(() => {
    if (appSettings && appSettings.theme && appSettings.theme.length > 0) {
      appSettings.theme.forEach((item) => {
        document.body.style.setProperty(item.key, item.value);
      });
    }
  }, [appSettings]);

  const showMessage = useAxiomEvent(props.appName, props.appID, "AxiomLayout", props.componentID);

  const { pathname } = useLocation();

  useEffect(() => {
    const isMobileDevice = checkMobileDevice(
      appSettings ? appSettings.forcefullyDisableMobileView : false
    );
    if (!window.location.href.toString().includes("change-password")) {
      dispatch({ isMobileDevice: isMobileDevice }, "AxiomEvent", "GetDashboardAndApps");
    } else {
      dispatch({
        loading: false,
      });
    }
  }, [dispatch, appSettings]);

  const landingPageApps = state.favouriteApps;

  const favouriteApp = useCallback(
    (event, app) => {
      event.preventDefault();
      const isAlreayFavourite = state.favouriteApps.some((d) => d.name === app.name);
      if (!isAlreayFavourite) {
        dispatch({ loading: true });
        dispatch({ app }, "AxiomEvent", "FavouriteApp");
      }
    },
    [dispatch, state.favouriteApps]
  );

  const unfavouriteApp = useCallback(
    (event, app) => {
      event.preventDefault();
      dispatch({ loading: true });
      dispatch({ app }, "AxiomEvent", "UnfavouriteApp");
    },
    [dispatch]
  );

  const changePassword = useCallback(
    (oldPassword, newPassword) => {
      AxiomAPIs.updatepassword(newPassword, oldPassword, (cb) => {
        if (cb.status) {
          AxiomAPIs.logout(() => {});
          location.href = window.location.origin + window.location.pathname;
        } else {
          showMessage({
            snackOpen: true,
            snackMessage: cb.error.message,
            snackType: "error",
          });
        }
      });
    },
    [showMessage]
  );

  const logo = appSettings && appSettings.appLogo ? appSettings.appLogo : "images/hawkeye-logo.svg";

  const history = useHistory();

  useEffect(() => {
    if (!window.location.href.toString().includes("change-password")) {
      if (landingPageApps.length == 0) {
        history.replace({ pathname: "/all-apps" });
      } else {
        history.replace({ pathname: "/favourite-apps" });
      }
    }
  }, [landingPageApps, history]);

  const handle_prompt = (_) => {
    props.beforeinstallprompt.prompt();
    setclosemodelAddtoHome(false);
  };

  return (
    <div className="axiom-home">
      {state.loading && (
        <AxiomLoader
          background={"rgba(255, 255, 255, 0.4)"}
          fullScreen={true}
          block=".axiom-home"
        />
      )}
      <div className={`axiom-home-navbar ${pathname.substr(1)}`}>
        <Link to="/favourite-apps" className="axiom-home-logo" tabIndex="0">
          <img src={logo} className="axiom-home-logo-image" />
        </Link>
        {props.userInfo && (
          <div className="axiom-home-user">
            {props.userInfo.profilePhoto ? (
              <img className="axiom-home-user-avatar" src={props.userInfo.profilePhoto} />
            ) : (
              <span className="axiom-home-user-avatar">{props.userInfo.name.charAt(0)}</span>
            )}
            <div className="axiom-home-user-info">
              <div className="axiom-home-user-name">
                {props.userInfo ? props.userInfo.name : ""}
              </div>
              <div className="axiom-logout-pass-wrapper">
                <a onClick={props.logout} tabIndex="0" className="axiom-home-user-logout">
                  {AxiomAPIs.translate("Logout")}
                </a>
                {props.isShowChangePassword ? (
                  <div className="logout-pass-seprator-wrapper">
                    <div className="logout-pass-seprator">|</div>
                    <a
                      href="/apps/Home/#/change-password"
                      tabIndex="0"
                      className="axiom-home-user-change-pass"
                    >
                      {AxiomAPIs.translate("Change Password")}
                    </a>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </div>
      <Fragment>
        <Route path={"/favourite-apps"}>
          <LandingPage
            loading={state.loading}
            appSettings={appSettings}
            apps={landingPageApps}
            unfavouriteApp={unfavouriteApp}
          />
        </Route>
        <Route path={"/change-password"}>
          <ChangePassword
            appName={props.appName}
            appID={props.appID}
            componentID={props.componentID}
            changePassword={changePassword}
            userInfo={userInfo}
          />
        </Route>

        <Route path={"/all-apps"}>
          <AllApps
            loading={state.loading}
            apps={state.apps}
            favouriteApps={state.favouriteApps}
            favouriteApp={favouriteApp}
            unfavouriteApp={unfavouriteApp}
            appSettings={appSettings}
          />
        </Route>
        <Route exact path="/">
          <Redirect to="/favourite-apps" />
        </Route>
      </Fragment>
      {appSettings &&
      appSettings.appCopyRight &&
      appSettings.appCopyRight.copyrightText &&
      appSettings.appCopyRight.isCopyRightText.toString() == "true" ? (
        <div className="copyright-home-bottom-container">
          {appSettings.appCopyRight.copyrightText}
        </div>
      ) : null}
      {props.beforeinstallprompt && CloseModelAddtoHome && (
        <div style={{}}>
          {" "}
          <div className="axiom-home-AddtoHome-box" style={{}}>
            <img src={"images/jarvisHeaderLogo.png"} className="axiom-home-AddtoHome-Logo" />
            <div className="axiom-Home-page-ADDtoHomeBtn">
              <span onClick={handle_prompt}>Add JARVIS to Home Screen</span>
            </div>
            <div
              className="axiom-Home-page-ADDtoHomeBtn"
              id="InstallBtn"
              onClick={() => {
                setclosemodelAddtoHome(false);
              }}
            >
              <span> X </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default memo(Home);
