const languages = {
  en: {
    label: "English",
    value: "English",
    folderName: "en",
  },
  fr: {
    label: "France",
    value: "Français",
    folderName: "fr",
  },
  es: {
    label: "Spanish",
    value: "Español",
    folderName: "es",
  },
  tr: {
    label: "Turkish",
    value: "Türkçe",
    folderName: "tr",
  },
  hi: {
    label: "Hindi",
    value: "हिंदी",
    folderName: "hindi",
  },
  THA: {
    label: "Thai",
    value: "THA",
    folderName: "THA",
  },
  bangladesh: {
    label: "Bangla",
    value: "Bangladesh",
    folderName: "bangladesh",
  },
};
module.exports = { languages };
