// React import is required to transpile the icons to react components
import React from "react";

export default {
  IconLogout: (props) => (
    <svg className={props.className || " "} viewBox="0 0 33 32">
      <path d="M0.473 17.046l8.421 8.367 2.232-2.209-5.756-5.694h19.032v-3.099h-19.071l5.795-5.694-2.232-2.209-8.46 8.367c-0.312 0.308-0.468 0.699-0.468 1.085s0.195 0.816 0.507 1.085z"></path>
      <path d="M16.805 3.099h13.198v25.803h-13.198v-8.562h-3.134v10.111c0 0.851 0.706 1.549 1.565 1.549h16.336c0.859 0 1.565-0.699 1.565-1.549v-28.901c0-0.851-0.706-1.549-1.565-1.549h-16.332c-0.862 0-1.565 0.699-1.565 1.549v10.419h3.13v-8.87z"></path>
    </svg>
  ),
  IconChangePasssword: (props) => (
    <svg className={props.className || " "} viewBox="0 0 12 15">
      <path d="M12 7.5C12 6.67275 11.3273 6 10.5 6H9.75V3.75C9.75 1.68225 8.06775 0 6 0C3.93225 0 2.25 1.68225 2.25 3.75V6H1.5C0.67275 6 0 6.67275 0 7.5V13.5C0 14.3273 0.67275 15 1.5 15H10.5C11.3273 15 12 14.3273 12 13.5V7.5ZM3.75 3.75C3.75 2.5095 4.7595 1.5 6 1.5C7.2405 1.5 8.25 2.5095 8.25 3.75V6H3.75V3.75Z" />
    </svg>
  ),
};
