import React from "react";
import PropTypes from "prop-types";

import Tile from "../Tile.jsx";

import { getBeginOfDay, getDay, getEndOfDay, getISOLocalDate, isWeekend } from "../shared/dates";
import { tileProps } from "../shared/propTypes";

const className = "react-calendar__month-view__days__day";

const matchDate = (dates, date) => {
	for (let i = 0; i < dates.length; i++) {
		let d1 = new Date(dates[i]);
		let d2 = new Date(date);
		if (d1.getTime() === d2.getTime()) {
			return true;
		}
	}
};

const Day = ({ classes, currentMonthIndex, date, ...otherProps }) => (
	<Tile {...otherProps} classes={[...classes, matchDate(otherProps.disabledDates, date) ? "day__disabled" : "", className, isWeekend(date) ? `${className}--weekend` : null, date.getMonth() !== currentMonthIndex ? `${className}--neighboringMonth` : null]} date={date} dateTime={`${getISOLocalDate(date)}T00:00:00.000`} maxDateTransform={getEndOfDay} minDateTransform={getBeginOfDay} view="month">
		{getDay(date)}
	</Tile>
);

Day.propTypes = {
	currentMonthIndex: PropTypes.number.isRequired,
	...tileProps
};

export default Day;
