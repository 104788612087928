import React, { memo, useMemo } from "react";
import { Link } from "react-router-dom";
import "./LandingPage.scss";
import LandingPageCard from "../LandingPageCard/LandingPageCard";

function LandingPage(props) {
  const { loading, appSettings, apps, unfavouriteApp } = props;
  const backgroundStyle = {};
  if (appSettings && appSettings.dashboardBackground) {
    backgroundStyle.backgroundImage = `url(${appSettings.dashboardBackground})`;
  }

  const extraButtons = useMemo(() => {
    return appSettings != null && appSettings.extraButtons != null ? appSettings.extraButtons : [];
  }, [appSettings]);

  return (
    <div style={backgroundStyle} className="axiom-landing-page">
      <div className="axiom-landing-page-content">
        {extraButtons.length >= 0 && (
          <div className="axiom-landing-apps extra-buttons">
            {extraButtons.map((app) => (
              <LandingPageCard app={app} showAppIcon={true} />
            ))}
          </div>
        )}
        <div className="axiom-landing-page-heading">
          <h1 className="axiom-landing-page-title">{AxiomAPIs.translate("Favourite Apps")}</h1>
          <Link to="/all-apps" className="axiom-landing-page-more">
            {AxiomAPIs.translate("All Apps")}
          </Link>
        </div>
        <div className="axiom-landing-apps">
          {apps.map((app) => (
            <LandingPageCard app={app} favouriteAppFunction={unfavouriteApp} favApps={true} />
          ))}
          {!loading && apps.length === 0 && (
            <div className="app-blank-message">
              You don't have any favourite apps. Click on all apps to grab some :)
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default memo(LandingPage);
