import React, { memo, useEffect, useCallback } from "react";
import "./ChangePassword.scss";
import configStructure from "./configStructure.js";
import { useAxiomState, useAxiomEvent } from "__AxiomComponents/AxiomHooks/AxiomHooks";

function ChangePassword(props) {
  const [state, dispatch] = useAxiomState(
    {
      loading: false,
      oldPassword: "",
      newPassword: "",
      reTypeNewPassword: "",
    },
    props.appName,
    props.appID,
    "ChangePassword",
    props.componentID
  );
  const { changePassword } = props;
  const showMessage = useAxiomEvent(props.appName, props.appID, "AxiomLayout", props.componentID);

  const onChange = useCallback(
    (type, value) => {
      switch (type.toLowerCase()) {
        case "oldpassword":
          dispatch({
            oldPassword: value.target.value,
          });
          break;
        case "newpassword":
          dispatch({
            newPassword: value.target.value,
          });
          break;
        case "retypenewpassword":
          dispatch({
            reTypeNewPassword: value.target.value,
          });
          break;
      }
    },
    [dispatch]
  );

  const onPassword = useCallback(() => {
    const regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;
    if (state.newPassword == state.reTypeNewPassword) {
      if (state.newPassword.match(regularExpression)) {
        changePassword(state.oldPassword, state.newPassword);
      } else {
        showMessage({
          snackOpen: true,
          snackMessage:
            "Password must have 8 to 16 valid characters at least a number and at least a special character.",
          snackType: "error",
        });
      }
    } else {
      showMessage({
        snackOpen: true,
        snackMessage: "New password and retype password are not same",
        snackType: "error",
      });
    }
  }, [state.oldPassword, state.newPassword, changePassword, showMessage, state.reTypeNewPassword]);

  const onCancle = useCallback(() => {
    window.history.back(() => {});
  }, []);
  return (
    <div className="ChangePassword">
      <div className="change-password-wrapper">
        <h2 className="change-password-label">Change Password</h2>
        <div className="old-password-wrapper">
          <div className={"label-change-password"}>Email</div>
          <div className="label-email-change-password" disabled>
            {props.userInfo.email}
          </div>
        </div>
        <div className="old-password-wrapper">
          <div className={"label-change-password"}>Old Password</div>
          <input
            type="password"
            className="input-change-password"
            placeholder=" "
            onChange={(e) => onChange("oldpassword", e)}
            value={state.oldPassword}
          />
        </div>
        <div className="old-password-wrapper">
          <div className={"label-change-password"}>New Password</div>
          <input
            type={"password"}
            className="input-change-password"
            placeholder=" "
            onChange={(e) => onChange("newpassword", e)}
            value={state.newPassword}
          />
        </div>
        <div className="old-password-wrapper">
          <label className={"label-change-password"}>Re-type New Password</label>
          <input
            type={"password"}
            className="input-change-password"
            placeholder=" "
            onChange={(e) => onChange("retypenewpassword", e)}
            value={state.reTypeNewPassword}
          />
        </div>
        <div className="change-password-Action">
          <div>
            <button onClick={onPassword} className="change-password-btn">
              Update
            </button>
            <button onClick={onCancle} className="change-password-btn">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(ChangePassword);
