import React from "react";

import "./AxiomSnackBar.scss";

export default class AxiomSnackBar extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const typeClass = this.props.type ? this.props.type : "info";
    const positionClass = this.props.position ? this.props.position : "center";
    const snackErrorClass = this.props.snackErrorClass ? this.props.snackErrorClass : "";

    if (this.props.snackOpen) {
      setTimeout(() => {
        if (this.props.onClose) {
          this.props.onClose();
        }
      }, this.props.autoHideDuration || 5000);
    }

    return (
      <div
        className={`axiom-snackbar ${
          this.props.snackOpen ? "is-showing" : ""
        } ${typeClass} ${positionClass} ${snackErrorClass}`}
      >
        {this.props.message}
        {this.props.onClose && (
          <button className="axiom-snackbar-dismiss" onClick={this.props.onClose}>
            &times;
          </button>
        )}
      </div>
    );
  }
}
