import React from "react";
import PropTypes from "prop-types";
import "./AxiomLoginNew.css";
import AxiomLoader from "../AxiomUI/AxiomLoader/AxiomLoader.jsx";
import AxiomSnackBar from "../AxiomSnackBar/AxiomSnackBar.jsx";
import AxiomLoginForm from "./AxiomLoginForm.jsx";
import AxiomModal from "../AxiomModal/AxiomModal.js";
import AxiomLoginD3Viz from "./AxiomLoginD3Viz.js";
import LogInWithBubble from "./LogInWithBubble.js";
import LogInWithImage from "./LogInWithImage.js";

export default class AxiomLoginNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      email:
        window.localStorage.getItem("savedUser") &&
        window.localStorage.getItem("savedUser") != "undefined" &&
        window.localStorage.getItem("savedUser") != "null"
          ? window.localStorage.getItem("savedUser")
          : "",
      password: "",
      snackOpen: false,
      accessTokenAPICalled: false,
      message: "",
      showMFAAuthScreen: false,
      loginOptions: [],
      showPassword: false,
      forgotPasswordView: false,
      rememberMe:
        window.localStorage.getItem("savedUser") &&
        window.localStorage.getItem("savedUser") != "undefined" &&
        window.localStorage.getItem("savedUser") != "null"
          ? true
          : false,
    };

    this.login = this.login.bind(this);
  }

  componentDidMount() {
    var that = this;
    AxiomAPIs.onInitialized(function () {
      AxiomAPIs.getAccessToken(function (result) {
        if (result.status == "false") {
          if (
            result.loginOptions &&
            result.loginOptions.length == 1 &&
            !result.isMonitorLoginEnabled
          ) {
            // If we have only one login option and monitor login is also disabled
            // redirect user to the login uri directly
            window.location = window.location.origin + result.loginOptions[0].uri;
          } else {
            that.setState({
              loading: false,
              accessTokenAPICalled: true,
              loginOptions: result.loginOptions,
              isMonitorLoginEnabled: !!result.isMonitorLoginEnabled,
              headerText: result.loginHeaders.header,
              subHeaderText: result.loginHeaders.subHeader,
              newLoginOptions: result.newLoginOptions,
              hideForgotPassword: result.hideForgotPassword,
            });
          }
        } else {
          var event = new CustomEvent("AxiomEventInfo", {
            detail: {
              appName: that.props.appName,
              appID: that.props.appID,
              componentID: that.props.componentID,
              componentName: that.props.componentName,
              eventName: "user_logged_in",
              data: {
                logged: true,
              },
            },
          });
          AxiomAPIs.dispatchEvent(event);
        }
      });
    });
  }

  login() {
    if (this.state.rememberMe) {
      window.localStorage.setItem("savedUser", this.state.email);
    } else {
      window.localStorage.removeItem("savedUser");
    }
    this.setState({ loading: true });
    var that = this;
    AxiomAPIs.postLogin(that.state.email, that.state.password, null, function (result) {
      if (!result || result.status == false) {
        that.setState({ loading: false, snackOpen: true, message: result.content.message });
      } else if (result && result.status && result.content.isMFAEnabled) {
        that.setState({
          loading: false,
          showMFAAuthScreen: true,
          showModal: true,
        });
      } else {
        AxiomAPIs.getAccessToken(function () {
          var event = new CustomEvent("AxiomEventInfo", {
            detail: {
              appName: that.props.appName,
              appID: that.props.appID,
              componentID: that.props.componentID,
              componentName: that.props.componentName,
              eventName: "user_logged_in",
              data: { logged: true },
            },
          });
          AxiomAPIs.dispatchEvent(event);
        });
      }
    });
  }

  validateOTP(loginOtp) {
    let that = this;
    if (!loginOtp || isNaN(Number(loginOtp)) || loginOtp.toString().length != 4) {
      this.setState({ snackOpen: true, message: "Invalid OTP! Enter required digits" });
    } else {
      AxiomAPIs.validateOTP(loginOtp, function (result) {
        if (!result || !result.status) {
          // throw error as otp/password is invalid
          that.setState({ loading: false, snackOpen: true, message: result.content.message });
        } else {
          AxiomAPIs.getAccessToken(function () {
            var event = new CustomEvent("AxiomEventInfo", {
              detail: {
                appName: that.props.appName,
                appID: that.props.appID,
                componentID: that.props.componentID,
                componentName: that.props.componentName,
                eventName: "user_logged_in",
                data: { logged: true },
              },
            });
            AxiomAPIs.dispatchEvent(event);
          });
          // set logged to true & authorize user
        }
      });
    }
  }

  onInputChange = (name, e) => {
    this.setState({ [name]: e.target.value });
  };

  onShowPasswordChange = (e) => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  onRememberMeChange = (e) => {
    this.setState({ rememberMe: !this.state.rememberMe });
  };

  onOtpModalClose = () => {
    this.setState({ showModal: false });
  };

  onSnackbarClose = () => {
    this.setState({ snackOpen: false });
  };

  forgotPassword = () => {
    this.setState({
      forgotPasswordView: !this.state.forgotPasswordView,
    });
  };

  sendPasswordLink = () => {
    if (this.state.email) {
      const that = this;
      this.setState({
        loading: true,
      });
      const url = `${getBaseUrlString()}/apps/PasswordReset`;
      AxiomAPIs.forgotPassword(this.state.email, url, function (result) {
        that.setState({ loading: false, snackOpen: true, message: result.content.message });
      });
    }
  };

  render() {
    return (
      <div>
        {this.state.loading && <AxiomLoader block=".axiom-login" fullScreen={true} />}
        {this.state.accessTokenAPICalled && this.state.newLoginOptions && (
          <LogInWithImage
            email={this.state.email}
            password={this.state.password}
            title={this.state.newLoginOptions.title}
            description={this.state.newLoginOptions.description}
            backgroundImage={this.state.newLoginOptions.backgroundImage}
            brandLogo={this.state.newLoginOptions.brandLogo}
            loginWithTitle={this.state.newLoginOptions.loginWithTitle}
            brandLogoLeftPanel={this.state.newLoginOptions.brandLogoLeftPanel}
            onChange={this.onInputChange}
            doLogin={this.login}
            loginOptions={this.state.loginOptions}
            welcomeLabel={this.state.newLoginOptions.welcomeLabel}
            onShowPasswordChange={this.onShowPasswordChange}
            showPassword={this.state.showPassword}
            rememberMe={this.state.rememberMe}
            onRememberMeChange={this.onRememberMeChange}
            copyrightText={this.state.newLoginOptions.copyrightText}
            isCopyRightText={this.state.newLoginOptions.isCopyRightText}
            hideForgotPassword={this.state.hideForgotPassword}
            forgotPasswordView={this.state.forgotPasswordView}
            forgotPassword={this.forgotPassword}
            sendPasswordLink={this.sendPasswordLink}
          />
        )}
        {this.state.accessTokenAPICalled && !this.state.newLoginOptions && (
          <LogInWithBubble
            includeHeaderText={this.props.includeHeaderText}
            includeSubHeaderText={this.props.includeSubHeaderText}
            includeAppLogo={this.props.includeAppLogo}
            headerText={this.props.headerText || this.state.headerText}
            subHeaderText={this.props.subHeaderText || this.state.subHeaderText}
            appLogo={this.props.appLogo}
            loginLogoHeight={this.props.loginLogoHeight || 30}
            onChange={this.onInputChange}
            email={this.state.email}
            password={this.state.password}
            doLogin={this.login}
            isMonitorLoginEnabled={this.state.isMonitorLoginEnabled}
            loginOptions={this.state.loginOptions}
            onShowPasswordChange={this.onShowPasswordChange}
            showPassword={this.state.showPassword}
            rememberMe={this.state.rememberMe}
            onRememberMeChange={this.onRememberMeChange}
            hideForgotPassword={this.state.hideForgotPassword}
            forgotPasswordView={this.state.forgotPasswordView}
            forgotPassword={this.forgotPassword}
            sendPasswordLink={this.sendPasswordLink}
          />
        )}
        {this.state.showMFAAuthScreen && (
          <AxiomModal isOpen={this.state.showModal} onClose={this.onOtpModalClose}>
            <label>Enter OTP: </label>
            <input
              type="text"
              autoFocus={true}
              onKeyPress={this.handleKeyPress}
              onChange={(e) => this.onInputChange("userOTP", e)}
            />
            <br />
            <button
              className="axiom-otp-validation-button"
              onClick={() => this.validateOTP(this.state.userOTP)}
            >
              {" "}
              Submit{" "}
            </button>
          </AxiomModal>
        )}
        <AxiomSnackBar
          snackOpen={this.state.snackOpen}
          onClose={this.onSnackbarClose}
          message={this.state.message}
        />
      </div>
    );
  }
}
