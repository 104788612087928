const numeral = require("numeral");
const DateLibrary = require("axiom-date-library");
numeral.register("format", "thai", {
  regexps: {
    format: /(฿)/,
    unformat: /(฿)/,
  },
  format: function (value, format, roundingFunction) {
    var space = numeral._.includes(format, " ฿") ? " " : "",
      output;

    // check for space before ฿
    format = format.replace(/\s?\฿/, "");

    output = numeral._.numberToFormat(value, format, roundingFunction);

    if (numeral._.includes(output, ")")) {
      output = output.split("");

      output.splice(0, 0, "฿" + space);

      output = output.join("");
    } else {
      output = "฿" + space + output;
    }

    return output;
  },
  unformat: function (string) {
    return numeral._.stringToNumber(string) * 0.01;
  },
});
numeral.register("format", "bangladesh", {
  regexps: {
    format: /(৳)/,
    unformat: /(৳)/,
  },
  format: function (value, format, roundingFunction) {
    var space = numeral._.includes(format, " ৳") ? " " : "",
      output;

    // check for space before ৳
    format = format.replace(/\s?\৳/, "");

    output = numeral._.numberToFormat(value, format, roundingFunction);

    if (numeral._.includes(output, ")")) {
      output = output.split("");

      output.splice(0, 0, "৳" + space);

      output = output.join("");
    } else {
      output = "৳" + space + output;
    }

    return output;
  },
  unformat: function (string) {
    return numeral._.stringToNumber(string) * 0.01;
  },
});
numeral.register("format", "turkey", {
  regexps: {
    format: /(₺)/,
    unformat: /(₺)/,
  },
  format: function (value, format, roundingFunction) {
    var space = numeral._.includes(format, " ₺") ? " " : "",
      output;

    // check for space before ₺
    format = format.replace(/\s?\₺/, "");

    output = numeral._.numberToFormat(value, format, roundingFunction);

    if (numeral._.includes(output, ")")) {
      output = output.split("");

      output.splice(0, 0, "₺" + space);

      output = output.join("");
    } else {
      output = "₺" + space + output;
    }

    return output;
  },
  unformat: function (string) {
    return numeral._.stringToNumber(string) * 0.01;
  },
});
numeral.register("format", "Pakistan", {
  regexps: {
    format: /(Rs)/,
    unformat: /(Rs)/,
  },
  format: function (value, format, roundingFunction) {
    var space = numeral._.includes(format, " Rs") ? " " : "",
      output;

    // check for space before Rs
    format = format.replace(/\s?\Rs/, "");

    output = numeral._.numberToFormat(value, format, roundingFunction);

    if (numeral._.includes(output, ")")) {
      output = output.split("");

      output.splice(0, 0, "Rs" + space);

      output = output.join("");
    } else {
      output = "Rs" + space + output;
    }

    return output;
  },
  unformat: function (string) {
    return numeral._.stringToNumber(string) * 0.01;
  },
});
numeral.register("format", "Philippines", {
  regexps: {
    format: /(₱)/,
    unformat: /(₱)/,
  },
  format: function (value, format, roundingFunction) {
    var space = numeral._.includes(format, " ₱") ? " " : "",
      output;

    // check for space before ₱
    format = format.replace(/\s?\₱/, "");

    output = numeral._.numberToFormat(value, format, roundingFunction);

    if (numeral._.includes(output, ")")) {
      output = output.split("");

      output.splice(0, 0, "₱" + space);

      output = output.join("");
    } else {
      output = "₱" + space + output;
    }

    return output;
  },
  unformat: function (string) {
    return numeral._.stringToNumber(string) * 0.01;
  },
});

numeral.register("format", "Vietnam", {
  regexps: {
    format: /(₫)/,
    unformat: /(₫)/,
  },
  format: function (value, format, roundingFunction) {
    var space = numeral._.includes(format, " ₫") ? " " : "",
      output;

    // check for space before ₫
    format = format.replace(/\s?\₫/, "");

    output = numeral._.numberToFormat(value, format, roundingFunction);

    if (numeral._.includes(output, ")")) {
      output = output.split("");

      output.splice(0, 0, "₫" + space);

      output = output.join("");
    } else {
      output = "₫" + space + output;
    }

    return output;
  },
  unformat: function (string) {
    return numeral._.stringToNumber(string) * 0.01;
  },
});

export default class Utils {
  constructor() {}

  static leastSquares = (xSeries, ySeries) => {
    var reduceSumFunc = function (prev, cur) {
      return prev + cur;
    };

    var xBar = (xSeries.reduce(reduceSumFunc) * 1.0) / xSeries.length;
    var yBar = (ySeries.reduce(reduceSumFunc) * 1.0) / ySeries.length;

    var ssXX = xSeries
      .map(function (d) {
        return Math.pow(d - xBar, 2);
      })
      .reduce(reduceSumFunc);

    var ssYY = ySeries
      .map(function (d) {
        return Math.pow(d - yBar, 2);
      })
      .reduce(reduceSumFunc);

    var ssXY = xSeries
      .map(function (d, i) {
        return (d - xBar) * (ySeries[i] - yBar);
      })
      .reduce(reduceSumFunc);

    var slope = ssXY / ssXX;
    var intercept = yBar - xBar * slope;
    var rSquare = Math.pow(ssXY, 2) / (ssXX * ssYY);

    return {
      slope,
      intercept,
      rSquare,
    };
  };

  static rangeSliderGranularityEquivalent = (granularity) => {
    switch (granularity.toUpperCase()) {
      case "YEARLY_APRIL":
      case "YEARLY_APRIL_WITH_CURRENT_YTD":
        return "yearly-april";
      case "YEARLY_JANUARY":
      case "YEARLY_JANUARY_WITH_CURRENT_YTD":
        return "yearly-january";
      case "QUARTERLY":
      case "QUARTERLY_WITH_CURRENT_QTD":
        return "quarterly-april";
      case "MONTHLY":
      case "MONTHLY_WITH_CURRENT_MTD":
        return "month";
      case "DAILY":
        return "day";
      case "WEEKLY_SUNDAY":
      case "WEEKLY_SUNDAY_WITH_CURRENT_WTD":
        return "week-sunday";
      case "WEEKLY_MONDAY":
      case "WEEKLY_MONDAY_WITH_CURRENT_WTD":
        return "week-monday";
      default:
        return "month";
    }
  };

  static findTrendColor = (kpi) => {
    let xSeries = [],
      ySeries = [];

    let totalY = 0;
    kpi.trendData.map((trendPoint, index) => {
      xSeries.push(index + 1);
      ySeries.push(trendPoint.value);
      totalY += trendPoint.value;
    });

    const squares = Utils.leastSquares(xSeries, ySeries);

    let degree = (squares.slope / (totalY / ySeries.length)) * 100;

    // return color for those degrees
    if (degree < -30) {
      return "rgba(163, 2, 37, 1.0)"; // Red
    } else if (degree >= -30 && degree < -0.5) {
      return "rgba(245, 109, 67, 1.0)"; // Orange
    } else if (degree >= -0.5 && degree < 0.5) {
      return "rgba(253, 174, 97, 1.0)"; // Yellow
    } else if (degree >= 0.5 && degree < 30) {
      return "rgba(103, 189, 98, 1.0)"; // Light Green
    } else if (degree >= 30) {
      return "rgba(0, 104, 55, 1.0)"; // Dark Green
    } else {
      return "rgba(0, 104, 55, 1.0)"; // Dark Green
    }
  };

  static apply_format_unit(input, format, unit) {
    //For Rupees Cr.
    if (format && format.substring(format.length - 3) === "INR") {
      var negSign = "";
      if (input < 0) {
        negSign = "-";
        input *= -1;
      }
      var otherFormat = format && format.substring(0, format.length - 3);
      var abbr = "";
      if (input >= Math.pow(10, 7)) {
        // Cr.
        abbr = " Cr.";
        input = input / Math.pow(10, 7);
      } else if (input < Math.pow(10, 7) && input >= Math.pow(10, 5)) {
        // Lacs.
        abbr = " lacs";
        input = input / Math.pow(10, 5);
      } else if (input < Math.pow(10, 5) && input >= Math.pow(10, 3)) {
        // Thousands
        abbr = "k";
        input = input / Math.pow(10, 3);
      } else {
        //Less than thousand
        input = input;
      }
      // return negSign + "" + numeral(input).format(otherFormat);
      return abbr;
    } else if (unit == "Period") {
      var abbr = " day";
      if (input >= 365) {
        return " years"; //numeral(input / 365).format("00.0"); // + "years";
      } else if (input >= 30) {
        return " months"; // numeral(input / 30).format("00.0"); //+ "months";
      } else {
        return " days"; // numeral(input).format("00.0"); //+ "days";
      }
    } else if (unit == "ShortTextPeriod") {
      var abbr = " day";
      if (input >= 365) {
        return " yrs"; //numeral(input / 365).format("00.0"); //+ "yrs";
      } else if (input >= 30) {
        return " mths"; // numeral(input / 30).format("00.0"); //+ "mths";
      } else {
        return " days"; //numeral(input).format("00.0"); //+ "days";
      }
    }
    //  else if (unit.toLowerCase() == "percentage") {
    //   return "%";
    // }
    else {
      return "";
      //  numeral(input).format(format);
    }
  }

  static apply_format(input, format, unit) {
    //For Rupees Cr.
    if (format && format.substring(format.length - 3) === "INR") {
      var negSign = "";
      if (input < 0) {
        negSign = "-";
        input *= -1;
      }
      var otherFormat = format && format.substring(0, format.length - 3);
      var abbr = "";
      if (input >= Math.pow(10, 7)) {
        // Cr.
        abbr = "Cr.";
        input = input / Math.pow(10, 7);
      } else if (input < Math.pow(10, 7) && input >= Math.pow(10, 5)) {
        // Lacs.
        abbr = "L";
        input = input / Math.pow(10, 5);
      } else if (input < Math.pow(10, 5) && input >= Math.pow(10, 3)) {
        // Thousands
        abbr = "K";
        input = input / Math.pow(10, 3);
      } else {
        //Less than thousand
        input = input;
      }
      // Because numeral doesnot support scientifica notation like 5.224555e-12
      if (typeof input === "number") {
        input = input.toFixed(5);
      }
      unit = unit && unit.toUpperCase() == "RS" ? "Rs. " : "";
      return `${negSign} ${unit}${numeral(input).format(otherFormat)} ${abbr}`;
    } else if (unit == "Period") {
      var abbr = "day";
      // Because numeral doesnot support scientifica notation like 5.224555e-12
      if (typeof input === "number") {
        input = input.toFixed(5);
      }
      if (input >= 365) {
        return numeral(input / 365).format("00.0"); // + "years";
      } else if (input >= 30) {
        return numeral(input / 30).format("00.0"); //+ "months";
      } else {
        return numeral(input).format("00.0"); //+ "days";
      }
    } else if (unit == "ShortTextPeriod") {
      var abbr = "day";
      // Because numeral doesnot support scientifica notation like 5.224555e-12
      if (typeof input === "number") {
        input = input.toFixed(5);
      }
      if (input >= 365) {
        return numeral(input / 365).format("00.0"); //+ "yrs";
      } else if (input >= 30) {
        return numeral(input / 30).format("00.0"); //+ "mths";
      } else {
        return numeral(input).format("00.0"); //+ "days";
      }
    } else if (format && format.substring(format.length - 5) === "INRCr") {
      var negSign = "";
      if (input < 0) {
        negSign = "-";
        input *= -1;
      }
      var otherFormat = format && format.substring(0, format.length - 5);
      var abbr = "";
      if (input > Math.pow(10, 5)) {
        abbr = "Cr.";
        input = input / Math.pow(10, 7);
      } else if (input < Math.pow(10, 5)) {
        //Skip abbrevating for input less than a lakh
        abbr = "";
        input = numeral(input).format(format);
      }
      // Because numeral doesnot support scientifica notation like 5.224555e-12
      if (typeof input === "number") {
        input = input.toFixed(5);
      }
      unit = unit && unit.toUpperCase() == "RS" ? "Rs. " : "";
      return `${negSign} ${unit}${numeral(input).format(otherFormat)} ${abbr}`;
    } else if (format && format.substring(format.length - 4) === "INRK") {
      var negSign = "";
      if (input < 0) {
        negSign = "-";
        input *= -1;
      }
      var otherFormat = format && format.substring(0, format.length - 4);
      var abbr = "K";
      input = input / Math.pow(10, 3);
      // Because numeral doesnot support scientifica notation like 5.224555e-12
      if (typeof input === "number") {
        input = input.toFixed(5);
      }
      unit = unit && unit.toUpperCase() == "RS" ? "Rs. " : "";
      return `${negSign} ${unit}${numeral(input).format(otherFormat)} ${abbr}`;
    } else if (format && format.substring(format.length - 4) === "INRL") {
      var negSign = "";
      if (input < 0) {
        negSign = "-";
        input *= -1;
      }
      var otherFormat = format && format.substring(0, format.length - 4);
      var abbr = "L";
      input = input / Math.pow(10, 5);
      // Because numeral doesnot support scientifica notation like 5.224555e-12
      if (typeof input === "number") {
        input = input.toFixed(5);
      }
      unit = unit && unit.toUpperCase() == "RS" ? "Rs. " : "";
      return `${negSign} ${unit}${numeral(input).format(otherFormat)} ${abbr}`;
    } else if (format && format.substring(format.length - 10) === "USDmillion") {
      var negSign = "";
      if (input < 0) {
        negSign = "-";
        input *= -1;
      }
      var otherFormat = format && format.substring(0, format.length - 10);
      var abbr = "m";
      input = input / Math.pow(10, 6);
      // Because numeral doesnot support scientifica notation like 5.224555e-12
      if (typeof input === "number") {
        input = input.toFixed(5);
      }
      unit = unit && unit == "$" ? "$" : "";
      return `${negSign} ${unit}${numeral(input).format(otherFormat)}`;
    } else if (format && format.substring(format.length - 13) === "Indian_Format") {
      //Check input is a number with only max one decimal and has no. after decimal
      if (/^-?\d+(\.\d+)?$/.test(input)) {
        if (format.includes("."))
          input = Number.parseFloat(input).toFixed(format.substring(2, format.length - 13).length);
        else input = Number.parseFloat(input).toFixed(0);
        var negSign = "";
        if (input < 0) {
          negSign = "-";
          input *= -1;
        }
        input = input.toString();
        var afterPoint = "";
        if (input.indexOf(".") > 0) afterPoint = input.substring(input.indexOf("."), input.length);
        input = Math.floor(input).toString();
        var lastThree = input.substring(input.length - 3);
        var otherNumbers = input.substring(0, input.length - 3);
        if (otherNumbers != "") lastThree = "," + lastThree;
        var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint;
        unit = unit && unit.toUpperCase() == "RS" ? "Rs. " : "";
        return `${negSign} ${unit}${res}`;
      } else return input;
    } else {
      // Because numeral doesnot support scientifica notation like 5.224555e-12
      if (typeof input === "number") {
        input = input.toFixed(5);
      }
      return numeral(input).format(format);
    }
  }

  static getTranslation(transform) {
    // Create a dummy g for calculation purposes only. This will never
    // be appended to the DOM and will be discarded once this function
    // returns.
    var g = document.createElementNS("http://www.w3.org/2000/svg", "g");

    // Set the transform attribute to the provided string value.
    g.setAttributeNS(null, "transform", transform);

    // consolidate the SVGTransformList containing all transformations
    // to a single SVGTransform of type SVG_TRANSFORM_MATRIX and get
    // its SVGMatrix.
    var matrix = g.transform.baseVal.consolidate().matrix;

    // As per definition values e and f are the ones for the translation.
    return [matrix.e, matrix.f];
  }

  static getFiscYearInfo(inputDate) {
    var date = new Date(inputDate);
    var month = date.getMonth();
    var date = new Date(date);
    var quarterEndDate, quarterStartDate;
    var fiscalQuarter = "";
    var fiscalYear = date.getFullYear();
    var fiscalStartDate = new Date(date.getFullYear(), 3, 1, 0, 0, 0);
    fiscalStartDate.setMilliseconds(0);
    var fiscalEndDate = new Date(date.getFullYear(), 2, 31, 23, 59, 59);
    fiscalEndDate.setMilliseconds(999);
    if (month >= 0 && month <= 2) {
      date.setMonth(2);
      quarterEndDate = DateLibrary.getRelativeDate(date, {
        operationType: "Last_Date",
        granularityType: "Months",
      });
      fiscalYear -= 1;
      fiscalQuarter = "Q4";
      fiscalStartDate.setYear(fiscalYear);
      quarterStartDate = new Date(date.getFullYear(), 0, 1, 0, 0, 0);
      quarterStartDate.setMilliseconds(0);
    } else if (month >= 3 && month <= 5) {
      date.setMonth(5);
      quarterEndDate = DateLibrary.getRelativeDate(date, {
        operationType: "Last_Date",
        granularityType: "Months",
      });
      fiscalQuarter = "Q1";
      fiscalEndDate.setYear(fiscalYear + 1);
      quarterStartDate = new Date(date.getFullYear(), 3, 1, 0, 0, 0);
      quarterStartDate.setMilliseconds(0);
    } else if (month >= 6 && month <= 8) {
      date.setMonth(8);
      quarterEndDate = DateLibrary.getRelativeDate(date, {
        operationType: "Last_Date",
        granularityType: "Months",
      });
      fiscalQuarter = "Q2";
      fiscalEndDate.setYear(fiscalYear + 1);
      quarterStartDate = new Date(date.getFullYear(), 6, 1, 0, 0, 0);
      quarterStartDate.setMilliseconds(0);
    } else {
      date.setMonth(11);
      quarterEndDate = DateLibrary.getRelativeDate(date, {
        operationType: "Last_Date",
        granularityType: "Months",
      });
      fiscalQuarter = "Q3";
      fiscalEndDate.setYear(fiscalYear + 1);
      quarterStartDate = new Date(date.getFullYear(), 9, 1, 0, 0, 0);
      quarterStartDate.setMilliseconds(0);
    }
    quarterEndDate = d3.timeFormat("%Y-%m-%d")(quarterEndDate);
    return {
      FiscalQuarter: fiscalQuarter,
      QuarterStartDate: quarterStartDate,
      QuarterEndDate: quarterEndDate,
      FiscalYear: fiscalYear,
      fiscalStartDate: fiscalStartDate,
      fiscalEndDate: fiscalEndDate,
    };
  }

  static checkMobileDevice(forcefullyDisableMobileView) {
    if (forcefullyDisableMobileView) return false;
    if (
      /Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
      screen.width < 767
    ) {
      return true;
    }
  }
}
