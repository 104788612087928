import React, { memo, useState, useEffect, useMemo } from "react";
import "./AxiomLanguageSelection.scss";
import { languages } from "./languageMapping.js";
import "./AxiomLanguageSelection.scss";
import AxiomLoader from "__AxiomComponents/AxiomUI/AxiomLoader/AxiomLoader.jsx";

function AxiomLanguageSelection(props) {
  const [isSaveLanguage, onSaveLaterCheckbox] = useState(false);
  const [isSelectedLanguageIndex, onSelectedLanguage] = useState(0);
  const [loading, setLoading] = useState(true);

  const options = useMemo(() => {
    const supportedLanguages = props.supportedLanguages;
    let options = [];
    supportedLanguages.forEach((d, index) => {
      let obj = { ...languages[d] };
      if (obj.label != null) {
        obj.label =
          obj.label.trim() === obj.value.trim()
            ? `${languages[d].label}`
            : `${languages[d].label} (${languages[d].value})`;
        obj.value = d;
        options.push(obj);
      }
    });
    return options;
  }, [props.supportedLanguages]);

  useEffect(() => {
    if (options && options.length) {
      if (props.preferredLanguage) {
        const findLanguageIndex = options.findIndex((d) => d.value == props.preferredLanguage);
        onSelectedLanguage(findLanguageIndex);
      } else {
        const getBrowserSetLanguage = navigator.language || navigator.userLanguage;
        const findLanguageIndex = options.findIndex((d) => d.value == getBrowserSetLanguage);
        if (findLanguageIndex != -1) {
          onSelectedLanguage(findLanguageIndex);
        } else {
          onSelectedLanguage(0);
        }
      }
    }
  }, [props.preferredLanguage, options]);

  const onValueChange = (index) => {
    onSelectedLanguage(index);
  };

  const onToggleCheckbox = (event) => {
    onSaveLaterCheckbox(event.target.checked);
  };

  return (
    <div className="AxiomLanguageSelection">
      {options && options.length ? (
        <div className="AxiomLanguageSelection-wrapper">
          <form
            onSubmit={(e) =>
              props.preferredLanguageSave(e, isSaveLanguage, options[isSelectedLanguageIndex])
            }
          >
            <div className="AxiomLanguageSelection-first-part">
              <div className="AxiomLanguageSelection-header">
                {AxiomAPIs.translate("Select Preferred Language")}
              </div>
              <div className="landing-page-radio">
                {options.map((option, i) => (
                  <div key={i} className="landing-page-radio-item">
                    <input
                      type="radio"
                      className="AxiomLanguageSelection-form-control"
                      value={option.value}
                      checked={i == isSelectedLanguageIndex ? true : false}
                      onChange={() => onValueChange(i)}
                    />
                    <div className="landingpage-radio-label"> {option.label}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className="AxiomLanguageSelection-bottom">
              <input
                className="AxiomLanguageSelection-checkbox"
                type="checkbox"
                onChange={onToggleCheckbox}
                checked={isSaveLanguage ? isSaveLanguage : false}
              />
              <div className="landing-page-bottom-text">
                {AxiomAPIs.translate("Save for later")}
              </div>
            </div>
            <div className="landing-page-btn-wrapper">
              <button className="landing-page-div" type="submit">
                <div className="landing-page-btn">{AxiomAPIs.translate("Done")}</div>
              </button>
            </div>
          </form>
        </div>
      ) : null}
      {loading ? <AxiomLoader block=".AxiomLanguageSelection" /> : null}
    </div>
  );
}

export default memo(AxiomLanguageSelection);
