import React from "react";
import * as d3 from "d3";
import Icons from "./Icons/Icons";

const width = 700;
const height = width;
const radius = (width / 2) * 0.7;
const itemCircleRadius = 6;
const itemCircleStroke = 3;
const biggerCircleRadius = 30;
const biggerCircleStroke = 4;
const numCircles = 70;
const transitionDuration = 2000;
const maxRandomRadius = 75;
const fixedAdditionalDegree = 5;
const maxAdditionalRandomDegree = 10;
const innerCircleRadius = radius * 0.85

const circleData = [];
const colorScale = d3.scaleOrdinal(d3.schemeCategory10);

let currSelectedIndex = 0;
let prevSelectedIndex = -1;
let selectedCounter = 0;

export default class AxiomLoginD3Viz extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.chart = React.createRef();
  }

  componentDidMount() {
    const svg = d3.select(this.chart.current)
      .style("width", "100%")
      .style("height", "auto")
      .style("padding", "50px")
      .style("box-sizing", "border-box");

    svg.append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", width)
      .attr("height", height)
      .style("fill", "rgba(0,0,0,0.0)");

    const icons = Object.keys(Icons)

    for (var i = 0; i < numCircles; i++) {
      let isBiggerCircle = (Math.random() > 0.5 && Math.random() > 0.5);
      let iconIndex = Math.floor(Math.random()*icons.length);
      circleData.push({
        isBiggerCircle: isBiggerCircle,
        index: i,
        currDegree: (i * 360 / numCircles),
        currentRadius: isBiggerCircle ? biggerCircleRadius : itemCircleRadius * (1 + Math.random() / 4),
        color: colorScale(parseInt(Math.random() * 10)),
        icon: icons[iconIndex]
      });
    }

    const itemCircles = svg.selectAll("item-circles")
      .data(circleData)
      .enter()
      .append("g")
      .attr("class", "item-circles")
      .attr("transform", (d) => {
        if (d.index === currSelectedIndex) {
          return "translate(" + ((width / 2) + "," + (height / 2)) + ")"
        } else {
          return "translate(" + (((width / 2) + (innerCircleRadius * Math.cos(d.currDegree * 2 * Math.PI / 360))) + "," + ((height / 2) + (innerCircleRadius * Math.sin(d.currDegree * 2 * Math.PI / 360)))) + ")";
        }
      })
      .style("opacity", (d) => d.index === currSelectedIndex ? 1 : 0);

    itemCircles.append("circle")
      .attr("cx", 0)
      .attr("cy", 0)
      .attr("r", (d) => d.index === currSelectedIndex ? innerCircleRadius : 0)
      .style("fill", "rgba(255,255,255,1)")
      .style("stroke", (d) => d.color)
      .style("stroke-width", (d) => (d.index === currSelectedIndex || d.isBiggerCircle) ? (biggerCircleStroke + "px") : (itemCircleStroke + "px"))
      .style("stroke-opacity", 0.6)
      ;

    svg.append("text")
      .attr("class","item-title")
      .attr("x", width/2)
      .attr("y", 220)
      .style("font-size","16px")
      .style("alignment-baseline","middle")
      .style("text-anchor","middle")
      .style("fill", "red")
      .text("")
      .style("opacity", 0);

    svg.append("image")
      .attr("class","item-image")
      .attr("x", (width/2))
      .attr("y", (height/2))
      .attr("height", 0)
      .attr("width", 0)
      .style("opacity", 0);
    
    this.autosize(this.chart.current);

    itemCircles.append("g")
    	.attr("transform", (d) => (d.index === currSelectedIndex ? "translate(-32, "+(-1*(innerCircleRadius-10))+") scale(2)" : "translate(-32, -32) scale(2)"))
    	.style("opacity", (d) => (d.index === currSelectedIndex || d.isBiggerCircle) ? 0 : 0)
    	.html((d)=>{
    		return Icons[d.icon].data
    	})
    	.style("fill",(d) => d.color)
    	.style("stroke",(d) => d.color)
    	.style("stroke-width",1);

    window.requestAnimationFrame(() => this.randomizeAll(svg, 1000))
  }

  randomizeData = (d) => {
    var oldDegree = d.currDegree;
    d.currDegree += (fixedAdditionalDegree + Math.random() * maxAdditionalRandomDegree);
    if (d.currDegree >= 360) {
      d.currDegree -= 360;
    }
    return d;
  }

  randomizeAll = (svg, duration) => {
    const component = this;
    let tansitionCounter = svg.selectAll(".item-circles").size();
    this.applyChange(svg.selectAll(".item-circles"), duration)
      .on('end', function(d) {
        tansitionCounter--;
        if (prevSelectedIndex === d.index) {
          prevSelectedIndex = -1;
          component.applyChange(d3.select(this), (duration != null ? duration : transitionDuration) - 500);
        }
        if (tansitionCounter == 0) {
          selectedCounter++;
          if (selectedCounter === 3) {
            d3.select(".item-title")
              .style("fill", "transparent")
              .style("opacity", 0);

            d3.select(".item-image")
              .style("opacity", 0)
              .attr("height", 0)
              .attr("width", 0)
              .attr("transform", "translate(" + 0 + "," + 0 + ")")
              ;
            selectedCounter = 0;
            prevSelectedIndex = currSelectedIndex;
            while (prevSelectedIndex == currSelectedIndex)
              currSelectedIndex = Math.floor(Math.random() * numCircles);
          }
          if(component.chart.current){
            window.requestAnimationFrame(() => component.randomizeAll(svg));
          }
        }
      });
  }

  applyChange = (selector, duration) => {
    const component = this;
    const returnVal = selector
      .each((d) => {
        component.randomizeData(d);
        if(d.index === currSelectedIndex){
          d3.select(".item-title")
            .transition()
            .duration(500)
            .style("fill", d.color)
            .style("opacity", 0.6)
            .style("font-family", "inherit")
            .text(Icons[d.icon].name);

          d3.select(".item-image")
            .attr("xlink:href", Icons[d.icon].image)
            .transition()
            .delay(250)
            .duration(500)
            .attr("height", 250)
            .attr("width", 300)
            .attr("transform", "translate(" + -150 + "," + -125 + ")")
            .style("opacity", 1)
            ;
        }
      })
      .transition()
      .ease(d3.easeLinear)
      .duration((d) => {
        if (currSelectedIndex === d.index || prevSelectedIndex === d.index) {
          return 500;
        } else
          return duration != null ? duration : transitionDuration;
      })
      .attr("transform", (d) => {
        if (currSelectedIndex === d.index)
          return "translate(" + (width / 2) + "," + (height / 2) + ")";
        else {
          return "translate(" + ((width / 2) + (radius + Math.random() * maxRandomRadius) * Math.cos(d.currDegree * 2 * Math.PI / 360)) + "," + ((height / 2) + (radius + Math.random() * maxRandomRadius) * Math.sin(d.currDegree * 2 * Math.PI / 360)) + ")";
        }
      })
      .style("opacity", 1)

    selector.select("circle")
      .transition()
      .ease(d3.easeLinear)
      .duration((d) => {
        if (currSelectedIndex === d.index || prevSelectedIndex === d.index) {
          return 500;
        } else
          return duration != null ? duration : transitionDuration;
      })
      .style("stroke-width", (d) => {
        if (currSelectedIndex === d.index || d.isBiggerCircle)
          return biggerCircleStroke + "px";
        else
          return itemCircleStroke + "px";
      })
      .attr("r", (d) => {
        if (currSelectedIndex === d.index)
          return innerCircleRadius;
        else
          return (d.isBiggerCircle == false && Math.random() > 0.5 && Math.random() > 0.5 && Math.random() > 0.5) ? biggerCircleRadius * 0.5 : d.currentRadius
      })

      selector.select("g")
	      .transition()
	      .ease(d3.easeLinear)
	      .duration((d) => {
	        if (currSelectedIndex === d.index || prevSelectedIndex === d.index) {
	          return 500;
	        } else
	          return duration != null ? duration : transitionDuration;
	      })
	    	.attr("transform", (d) => (d.index === currSelectedIndex ? "translate(-32, "+(-1*(innerCircleRadius-10))+") scale(2)" : "translate(-32, -32) scale(2)"))
	    	.style("opacity", (d) => (d.index === currSelectedIndex || d.isBiggerCircle) ? 0.6 : 0)
    return returnVal;
  }

  autosize = (svg) => {
    // In firefox accessing getBBox() on hidden element throws an error
    try {
      const box = svg.getBBox();
      svg.setAttribute("viewBox", `${box.x} ${box.y} ${box.width} ${box.height}`);
      return svg;
    } catch(e) {
      console.log(e);
    }
  }

  render() {
    return <svg ref={this.chart} />;
  }
}