import Utils from "__AxiomComponents/Utils";

const checkMobileDevice = Utils.checkMobileDevice;
const getIconColor = (name) => {
  let firstLetter = name.substr(0, 1).toLowerCase();
  switch (firstLetter) {
    case "a":
    case "b":
      return "#5398DE";
    case "c":
    case "d":
      return "#7049DD";
    case "e":
    case "f":
      return "#3A91AC";
    case "g":
    case "h":
      return "#2A3989";
    case "i":
    case "j":
      return "#49BADD";
    case "k":
      return "#DD49BC";
    case "l":
      return "#2C5F93";
    case "m":
      return "#43288E";
    case "n":
      return "#4961DD";
    case "o":
      return "#26935F";
    case "p":
      return "#7D36A8";
    case "q":
      return "#A13389";
    case "r":
      return "#7049DD";
    case "s":
      return "#D0B523";
    case "t":
      return "#2A8538";
    case "u":
      return "#1E5C3F";
    case "v":
      return "#40B753";
    case "w":
      return "#E28834";
    case "x":
      return "#937E11";
    case "y":
    case "z":
      return "#B46014";
    default:
      return "black";
  }
};
export { checkMobileDevice, getIconColor };
