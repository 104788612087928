import React from "react";
import PropTypes from "prop-types";
import "./AxiomLoginForm.css";

export default class AxiomLoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.monitorLogo = (
      <svg
        className="login-form-logo"
        viewBox="0 0 69 32"
        style={{ height: this.props.loginLogoHeight }}
      >
        <path
          fill="#3a6d73"
          style={{ fill: "#3a6d73" }}
          d="M0 21.443h4.811v10.557h-4.811v-10.557z"
        ></path>
        <path
          fill="#4b8b93"
          style={{ fill: "#4b8b93" }}
          d="M13.361 10.471h4.811v21.512h-4.811v-21.512z"
        ></path>
        <path fill="#58a7b3" style={{ fill: "#58a7b3" }} d="M26.704 0h4.811v32h-4.811v-32z"></path>
        <path
          fill="#a55d26"
          style={{ fill: "#a55d26" }}
          d="M37.227 27.189h10.557v4.811h-10.557v-4.811z"
        ></path>
        <path
          fill="#ee7f22"
          style={{ fill: "#ee7f22" }}
          d="M37.227 13.586h21.512v4.811h-21.512v-4.811z"
        ></path>
        <path fill="#fecd06" style={{ fill: "#fecd06" }} d="M37.227 0h32v4.811h-32v-4.811z"></path>
      </svg>
    );
  }

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.props.doLogin();
    }
  };

  render() {
    return (
      <div>
        {this.props.isMonitorLoginEnabled && (
          <div className="login-form">
            {this.props.includeAppLogo && this.props.appLogo ? (
              <img
                className="login-form-logo"
                src={this.props.appLogo}
                style={{ height: this.props.loginLogoHeight }}
              />
            ) : (
              this.monitorLogo
            )}
            {this.props.includeHeaderText && (
              <h1 className="login-form-header">{this.props.headerText}</h1>
            )}
            {this.props.includeSubHeaderText && (
              <h3 className="login-form-subheader">{this.props.subHeaderText}</h3>
            )}
            {this.props.forgotPasswordView ? (
              <>
                <div className="login-form-group">
                  <input
                    type="text"
                    className="login-form-control"
                    placeholder=" "
                    autoFocus={true}
                    onKeyPress={this.handleKeyPress}
                    onChange={(e) => this.props.onChange("email", e)}
                    value={this.props.email}
                  />
                  <label className={"login-form-label"}>Email</label>
                </div>
                <button onClick={this.props.sendPasswordLink} className="axiom-login-button">
                  SUBMIT
                </button>
                <div className="login-form-forgot-password" onClick={this.props.forgotPassword}>
                  LogIn
                </div>
              </>
            ) : (
              <>
                <div className="login-form-group">
                  <input
                    type="text"
                    className="login-form-control"
                    placeholder=" "
                    autoFocus={true}
                    onKeyPress={this.handleKeyPress}
                    onChange={(e) => this.props.onChange("email", e)}
                    value={this.props.email}
                  />
                  <label className={"login-form-label"}>Username</label>
                </div>
                <div className="login-form-group">
                  <input
                    type={this.props.showPassword ? "text" : "password"}
                    className="login-form-control"
                    placeholder=" "
                    onKeyPress={this.handleKeyPress}
                    onChange={(e) => this.props.onChange("password", e)}
                  />
                  <label className={"login-form-label"}>Password</label>
                  <label
                    className={"login-form-password-show-hide"}
                    onClick={(e) => this.props.onShowPasswordChange()}
                  >
                    {this.props.showPassword ? "Hide" : "Show"}
                  </label>
                </div>

                <div className="login-form-group login-form-remember-me-group">
                  <input
                    type="checkbox"
                    className="login-form-checkbox"
                    onChange={(e) => this.props.onRememberMeChange()}
                    defaultChecked={this.props.rememberMe}
                  />
                  <label className={"login-form-label login-form-remember-me-label"}>
                    Remember Me
                  </label>
                </div>
                <button onClick={this.props.doLogin} className="axiom-login-button">
                  LOGIN
                </button>
                {!this.props.hideForgotPassword && (
                  <div className="login-form-forgot-password" onClick={this.props.forgotPassword}>
                    Forgot Password?
                  </div>
                )}
              </>
            )}
          </div>
        )}
        {!!this.props.loginOptions.length && (
          <div className="login-form-options">
            <div className="login-form-seprator">
              <span className="login-form-seprator-label">OR</span>
            </div>
            <p>Login With</p>
            <div className="login-form-option-wrapper">
              {this.props.loginOptions.map((option, index) => {
                return (
                  <a className="login-form-option" href={option.uri} key={index}>
                    <img className="login-form-option-icon" src={option.logo} />
                  </a>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  }
}
